import {AppText} from "shared/UI/UIKit/Text";
import styles from "./Footer.module.scss";

export const AppFooter = () => {
  return (
    <footer className={styles.footer}>
      <AppText size={16} className={styles.text}>
        ФГБНУ «Институт содержания и методов обучения»
      </AppText>
    </footer>
  );
};